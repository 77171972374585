/* eslint-disable */
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

document.addEventListener('DOMContentLoaded', function() {
    const lightbox = new PhotoSwipeLightbox({
        gallery: '#gallery--simple',
        children: 'a',
        pswpModule: PhotoSwipe
    });

    lightbox.init();
});