/**
 * Glide Slider
 */

import Glide from '@glidejs/glide'

var sliders = document.querySelectorAll('.glide');

for (var i = 0; i < sliders.length; i++) {
    var glide = new Glide(sliders[i], {

        type: 'carousel',
        perView: 5,
        gap: 0,
        breakpoints: {
            991: {
                perView: 4,
            },
            500: {
                perView: 1,
            },
        },
    });
    glide.mount()
}
